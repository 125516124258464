.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

body.arbitrum {
  background-color: #676363;
}

body.fantom {
  background-color: #7ad0ff;
}

body.avalanche {
  background-color: #be3636;
}

body.aurora {
  background-color: #5ad33f;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.8);
}

.background-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background-color: rgba(200, 180, 255, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.heading {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 0 #000;
}

.subheading {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 0 #000;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

input {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #e5ccff;
}

button.create-pool-btn {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #7e41ff;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;
}

button.create-pool-btn:hover {
  background-color: #5d25cc;
}

.output {
  margin-top: 30px;
  text-align: center;
}

.output p {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}